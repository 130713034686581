
body{
  background-image: url('./../assets//img/Foto_fondo.webp');
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  /* width: 100%;
  height: 100vh; */
}

.Container-Main-Brand{
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 87vh;
  background-color: rgba(0, 0, 0, 0.73); 
  width: 100%;
}



.Text-Cy{
  /* font-size: 30px !important; */
  font-size: 0.9vw !important;
}

.Container-Footer{
  display: flex;
  background-color: rgba(0, 0, 0, 0.45); 
  flex-direction: row;
  width: 100%;
  padding: 10px 55px;
  height: 13vh;
  font-size: 2.2vh;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.Button-Contact{
  background-color: #FFFF00;
  border-radius: 25px;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  border: none;
  font-weight: 700;
  width: auto;
  color: inherit;
  text-decoration: none; 
  /* height: 7vh; */
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 0.6vw 1.5vw;
  /* padding: 7px 15px 7px 15px; */
}

.Logo-Whatsapp-Brand{
  width: 1.8vw;
}

.Container-Brand{
  display: flex;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}

@font-face {
  font-family: 'Ivy-Journal';
  /* src: local('Ivy-Journal'), url(../fonts/ivy/IvyJournal-Regular.ttf) format('opentype'); */
  src: local('Ivy-Journal'), url(../fonts/ivy/IvyJournal-Italic.ttf) format('opentype');
}

.Imagen-Texto-Logo{
  height: 10vh;
  margin: 30px;
  user-select: none;
  position: relative;
}

.Imagen-Logo{
  height: 15vh;
  position: relative;
  right: 20px;
  /* margin: -1rem -2rem 0 0; */
  margin: -2.5rem -5rem 0 0;
  user-select: none;
}

.Contenedor-Principal{
  min-width: 280px;
  height: 100%;
  font-size: 16px;
  overflow-x: hidden;
}

.Contenedor-Cuerpo-Principal{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -30px;
}
.Contenedor-Texto-Principal{
  height: 50vh !important;
    display: flex;
  justify-content: center;
  align-items: center;
}

.Contenedor-Cuerpo{
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  user-select: none;
}

.Contenedor-Cuerpo .Primer-Texto-Cuerpo{
  text-align: center;
  font-size: 2.5vh;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 4px 0;
}

.Contenedor-Cuerpo .Segundo-Texto-Cuerpo{
  text-align: center;
  font-size: 2.8vh;
  margin: 0;
  /* font-family: 'Source Serif Pro', serif; */
  font-family: 'Ivy-Journal', serif;
}

.Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
  text-align: center;
  font-size: 2.5vh;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  margin: 6px 0;
  font-weight: 500;
}

.Contenedor-Imagen-Principal{
  display: flex;
  justify-content: center;
}
.Contenedor-Contacto-Principal{
  display: flex;
  justify-content: center;
}

.Contenedor-Texto-Contacto{

  padding: 0 2px;
  display: flex;
  justify-content: unset;
  flex-direction: column;
  height: 25vh !important;
}

.App-Boton-Logo-Whatsapp{
  width: 2.5vh;
  height: 2.5vh
}

.App-Boton-Contacto{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 1vh;
  border: 0;
  background-color: #FFF856;
  cursor: pointer;
  text-decoration: none;
  color: black;
  width: 35vh;
  user-select: none;
}

.App-Texto-Contacto{
  font-weight: 800;
  margin: 0 1vh;
  font-family: 'Poppins', sans-serif;
  font-size: 2.5vh;
}

.Contenedor-Texto-Contacto .Primer-Texto-Contacto{
  text-align: center;
  margin: 2px 0;
  font-size: 2.5vh;
  text-decoration: none;
  color: black;
  font-family: 'Poppins', sans-serif;
  user-select: text;
  font-weight: 500;
}

.Contenedor-Texto-Contacto .Segundo-Texto-Contacto{
  text-align: center;
  font-size: 2.5vh;
  text-decoration: none;
  color: gray;
  font-family: 'Poppins', sans-serif;
}

.Estilo-Texto-Defecto{
  /* font-family: 'Times New Roman'; */
  font-family: 'Source Serif Pro', serif;
  /* font-weight: 500; */
  font-size: 2.5vh;
  user-select: text;
}

.Contenedor-Header{
  display: flex;
  justify-content: space-between;
  margin: 0;
  position: relative;
  width: 100%;
}

.Text-Main-Brand{
  display: flex;
  flex-direction: column;
  margin: 14px 0;
  justify-content: center;
  font-size: 2.5vw;
  /* font-size: 38px; */
  color: #FFFFFF;
}

.Text-Main-Brand div{
  display: flex;
  justify-content: center;
}

.Text-Button-Contanct{
  font-size: 1.5vw;
}


@media screen and (max-width: 770px) {
  .Info-Brand-Footer{
    display: flex;
    justify-content: center;
  }
}


@media screen and (max-width: 800px) {
  .Text-Main-Brand{
    font-size: 3vw;
  }
}

@media screen and (max-width: 520px) {
  .Text-Main-Brand{
    font-size: 3.5vw;
  }
}
/* @media screen and (max-width: 1195px) {
  .Text-Button-Contanct{
    font-size: 2vw;
  }
} */

@media screen and (max-width: 1000px) {
  .Text-Button-Contanct{
    font-size: 2vw;
  }
  .Logo-Whatsapp-Brand{
    width: 2.8vw;
  }
  /* .Button-Contact{
    padding: 5px 10px 5px 10px;
  } */
}
@media screen and (max-width: 683px) {
  .Text-Button-Contanct{
    font-size: 2.2vw;
  }
  .Logo-Whatsapp-Brand{
    width: 2.8vw;
  }
  .Button-Contact{
    padding: 0.8vw 1.7vw;
    /* padding: 4px 10px 4px 10px; */
    /* height: 5vh; */
  }
}
@media screen and (max-width: 525px) {
  .Text-Button-Contanct{
    font-size: 2.6vw;
  }
  .Logo-Whatsapp-Brand{
    width: 2.8vw;
  }
  .Button-Contact{
    /* padding: 4px 10px 4px 10px; */
    /* height: 5vh; */
  }
}

@media screen and (max-width: 425px) {
  .Text-Button-Contanct{
    font-size: 2.8vw;
  }
  .Logo-Whatsapp-Brand{
    width: 2.8vw;
  }
  .Button-Contact{
    /* padding: 4px 10px 4px 10px; */
    /* height: 5vh; */
  }
}

@media screen and (max-width: 385px) {
  .Text-Button-Contanct{
    font-size: 3vw;
  }
  .Logo-Whatsapp-Brand{
    width: 2.8vw;
  }
}

@media screen and (max-width: 2398px) {
  .Text-Cy{
    font-size: 0.9vw !important;
  }  
}
@media screen and (max-width: 1874px) {
  .Text-Cy{
    font-size: 0.8vw !important;
  }  
}
@media screen and (max-width: 1683px) {
  .Text-Cy{
    font-size: 1.1vw !important;
  }  
}

@media screen and (max-width: 1615px) {
  .Text-Cy{
    font-size: 0.8vw !important;
  }  
}

@media screen and (max-width: 1449px) {
  .Text-Cy{
    /* font-size: 1vw !important; */
    font-size: 1.1vw !important;
  }  
}

@media screen and (max-width: 1313px) {
  .Text-Cy{
    font-size: 0.8vw !important;
  }  
}

/* @media screen and (max-width: 1042px) {
  .Text-Cy{
    font-size: 2.8vw !important;
  }  
} */
@media screen and (max-width: 892px) {
  .Text-Cy{
    font-size: 2.5vw !important;
  }  
}
@media screen and (max-width: 734px) {
  .Text-Cy{
    font-size: 3vw !important;
  }  
}
 
@media screen and (max-width: 1037px) {
  .Container-Footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }
  .Info-Brand-Footer{
    display: flex;
    justify-content: center;
  }
  .Container-Footer div{
    justify-content: center;
  }
}
@media screen and (max-width: 711px) {
  .Container-Footer{
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 510px) {
  .Container-Footer{
    padding: 1px;
    font-size: 16px;
    justify-content: center;
  }
}
@media screen and (max-width: 400px) {
  .Container-Footer{
    padding: 1px;
    font-size: 12px;
    justify-content: center;
  }
}

/* 

.Imagen-Oficina{
  width: 40vh;
}

@media screen and (max-width: 640px) {
  .App-Contenedor-Principal {
    width: 70%;
  }

}

@media screen and (min-width: 220px) {
  .Contenedor-Texto-Contacto {
    margin: 0 10px;
  }

}
@media screen and (min-width: 289px) {
  .Contenedor-Texto-Contacto {
    margin: 0 10px;
  }
} */
@media screen and (min-width: 273px) {
  .Imagen-Logo {
    height: 17vh;
    z-index: 1;
    margin: -2rem -3rem 0 0;
    margin: -7vh -12vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -2vh;
  }


  body{
    overflow-x: hidden;
  }
  .Contenedor-Texto-Contacto {
    margin: 0 18px;
  }
  .Imagen-Texto-Logo{
    margin: 50px 0 0 50px;
  }
}
@media screen and (min-width: 341px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    /* margin: -3rem -4rem 0 0; */
    margin: -8vh -15vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -3vh;
  }

  /* .Imagen-Texto-Logo{
    margin: 50px 0 0 50px;
  } */
}

@media screen and (min-width: 455px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    margin: -5vh -15vh 0 0;
    /* margin: -1rem -1.8rem 0 0; */
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -5vh;
  }

/*   
  .Imagen-Texto-Logo{
    margin: 50px 0 0 50px;
  } */
}
@media screen and (min-width: 546px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    margin: -5vh -14vh 0 0;
    /* margin: -1.2rem -rem 0 0; */
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -15px;
  }
}
@media screen and (min-width: 683px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    /* margin: -1.2rem -2.7rem 0 0; */
    margin: -5vh -12vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -5vh;
    /* margin-top: -20px; */
  }
}
@media screen and (min-width: 780px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    /* margin: -1.4rem -2.8rem 0 0; */
    margin: -5vh -12vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    /* margin-top: -27px; */
    margin-top: -2vh;
  }
}

@media screen and (min-width: 910px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    /* margin: -1.7rem -3.1rem 0 0; */
    margin: -5vh -12vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    /* margin-top: -25px; */
    margin-top: -5vh;
  }

}
@media screen and (min-width: 1092px) {
  .Imagen-Logo {
    height: 20vh;
    z-index: 1;
    margin: -5vh -12vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -30px;
  }
}

@media screen and (min-width: 1241px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -10vh -15vh 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -7vh;
  }

  .Imagen-Texto-Logo{
    height: 9vh;
    margin: 40px 0 0 60px;
  }
}
@media screen and (min-width: 1366px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -3.8rem -5rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -8vh;
  }

  .Imagen-Texto-Logo{
    margin: 45px 0 0 65px;
  }
}
@media screen and (min-width: 1517px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -4.2rem -3.9rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -7vh;
  }

  .Imagen-Texto-Logo{
    margin: 50px 0 0 70px;
  }
}
@media screen and (min-width: 1707px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -4.7rem -4rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -65px;
  }

  .Imagen-Texto-Logo{
    margin: 55px 0 0 75px;
  }
  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    margin: 8px 0;
  }
}

@media screen and (min-width: 1821px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -5rem -4.2rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -68px;
  }

  .Imagen-Texto-Logo{
    margin: 60px 0 0 80px;
  }
}
@media screen and (min-width: 2049px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -5.5rem -4.7rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -75px;
  }

  .Imagen-Texto-Logo{
    margin: 70px 0 0 90px;
  }
}
@media screen and (min-width: 2732px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -7.2rem -5.8rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -98px;
  }

  .Imagen-Texto-Logo{
    margin: 90px 0 0 120px;
  }
}
@media screen and (min-width: 4098px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -11rem -8rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -130px;
  }

  .Imagen-Texto-Logo{
    margin: 130px 0 0 190px;
  }

  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    margin: 15px 0;
  }
}
@media screen and (min-width: 5464px) {
  .Imagen-Logo {
    height: 30vh;
    z-index: 1;
    margin: -15rem -10rem 0 0;
  }

  .Contenedor-Cuerpo-Principal{
    margin-top: -160px;
  }

  .Imagen-Texto-Logo{
    margin: 5vh 0 0 10vh;
  }

  .Imagen-Texto-Logo{
    margin: 160px 0 0 230px;
  }
  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    margin: 27px 0;
  }
}

@media screen and (max-width: 640px) {
  .Imagen-Texto-Logo {
    height: 5vh;
    margin: 7px;
  }
}

@media screen and (max-width: 640px) {
  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    font-size: 2vh;
    margin: 3px 0;
  }
  
}
@media screen and (max-width: 640px) {
  .Contenedor-Texto-Contacto .Segundo-Texto-Contacto{
    margin-top: 0;
  }
}

@media screen and (max-width: 640px) {
  .Contenedor-Texto-Contacto .Primer-Texto-Contacto , .Contenedor-Texto-Contacto .Segundo-Texto-Contacto{
    font-size: 2vh;
  }
}
@media screen and (max-width: 640px) {
  .Estilo-Texto-Defecto{
    font-size: 2vh;
  }
}
@media screen and (max-width: 640px) {

  .Contenedor-Cuerpo .Primer-Texto-Cuerpo{
    font-size: 2vh;
  }
  .Contenedor-Cuerpo .Segundo-Texto-Cuerpo{
    font-size: 2vh;
  }

  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    font-size: 2vh;
  }
  .App-Texto-Contacto{
    font-size: 2vh;
  }

  .App-Boton-Logo-Whatsapp{
    width: 2vh;
    height: 2vh;
  }
}
@media screen and (max-width: 1240px) {

  .Contenedor-Cuerpo .Primer-Texto-Cuerpo{
    font-size: 2vh;
    margin: 1vh 0;
  }
  .Contenedor-Cuerpo .Segundo-Texto-Cuerpo{
    font-size: 2vh;
  }

  .Contenedor-Cuerpo .Tercer-Texto-Cuerpo{
    font-size: 2vh;
    margin: 1vh 0;
  }
  .App-Texto-Contacto{
    font-size: 2vh;
  }

  .App-Boton-Logo-Whatsapp{
    width: 2vh;
    height: 2vh;
  }

  .Imagen-Texto-Logo{
    height: 5vh;
    margin: 3vh 0 0 3vh;
    
  }

  .Contenedor-Texto-Contacto .Primer-Texto-Contacto{
    font-size: 2vh;
  }
  .Contenedor-Texto-Contacto .Segundo-Texto-Contacto{
    font-size: 2vh;
  }
  .Contenedor-Texto-Contacto .Estilo-Texto-Defecto{
    font-size: 2vh;
  }
}
@media screen and (max-width: 280px) {
  .Primer-Texto-Cuerpo{
    font-size: 1vh;
  }

}

@media screen and (max-width: 640px) {
  .Contenedor-Imagen-Principal .Imagen-Oficina {
    width: 40vh;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .Imagen-Logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
